import React from 'react'
import { SupportMail } from '../../../assets'
import { Body, Button } from '../../../assets'
import email from '../../../assets/images/mail.svg'
import mark from '../../../assets/images/mark.svg'

const Contact = ({ showTitle }) => {
    return (
        <div className="container">
            <div>
                {showTitle ? (
                    <p style={{ fontSize: 20, fontWeight: 600, color: '#6D6E71' }}>Contáctanos</p>
                ) : (
                    <></>
                )}
                {showTitle ? <div className="copyright-text"></div> : <></>}
                <p style={{ fontSize: 16 }}>
                    <img src={email} width={20} />
                    <span style={{ color: Body.label.color }} className="pl-3">
                        {SupportMail}
                    </span>
                </p>
                <p style={{ fontSize: 16 }}>
                    <img src={mark} />
                    <span style={{ color: Body.label.color }} className="pl-3">
                        Edificio QPH Mallorca,
                        <br />
                        Quito - Ecuador.
                    </span>
                </p>
            </div>
        </div>
    )
}

export default Contact
