import React from 'react'
import Input from '../../../../components/Form/Input'
import { handleCode, handleEmail } from './helpers'
import CustomInput from '../../../../components/CustomInput'

const ContentFirstInput = ({
    control,
    errors,
    handleLoader,
    trigger,
    getValues,
    initialData,
    token,
    setError
}) => {
    return (
        <>
            <Input
                label="Nombres *"
                type="text"
                name="name"
                placeholder="Ingresar nombres"
                control={control}
                error={errors.name && errors.name?.message}
            />
            <Input
                label="Apellidos *"
                type="text"
                name="lastname"
                placeholder="Ingresar apellidos"
                control={control}
                error={errors.lastname && errors.lastname?.message}
            />
            <Input
                label="Código de vendedor *"
                type="text"
                name="code"
                placeholder="Ingresar código de vendedor"
                control={control}
                error={errors.code && errors.code?.message}
                onBlur={() =>
                    handleCode(handleLoader, trigger, getValues, initialData, token, setError)
                }
            />
            <Input
                label="Correo *"
                type="text"
                name="email"
                placeholder="Ingresar correo electrónico"
                control={control}
                error={errors.email && errors.email?.message}
                onBlur={() =>
                    handleEmail(handleLoader, trigger, getValues, initialData, token, setError)
                }
            />
            <CustomInput
                label="Usuario (para inicio de sesión)"
                type="text"
                name="username_edit"
                placeholder="Nombre de usuario"
                value={initialData?.username}
                disabled={true}
            />
        </>
    )
}

export default ContentFirstInput
