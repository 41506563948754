import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CustomLabel, CustomError } from '../style'
import { Input, Table } from '../../assets'
import eyeCloseSvg from '../../assets/images/eye-close.svg'
import eyeOpenSvg from '../../assets/images/eye-open.svg'

const InputWrapper = styled.div`
    background: ${Input.background};
    input {
        border-width: 1px;
        border-style: solid;
        border-color: ${(props) => (props.isBlue ? Input.borderColor : Table.action.border)};
        border-radius: 15px;
        padding: 29px 15px;
        background: #ffffff !important;

        ::placeholder {
            color: ${Input.placeholder};
            opacity: 1;
        }
        :focus {
            border-color: ${(props) => (props.isBlue ? Input.borderColor : Table.action.border)};
            box-shadow: none;
        }
    }
`
const EyeContentWrapper = styled.div`
    height: 23px;
    margin-top: -40px;
    position: relative;
    float: right;
`
const EyePasswordWrapper = styled.div`
    position: absolute;
    right: 25px;
    z-index: 10; 
    img {
        cursor: pointer;
    }
`

const CustomInput = ({ isBlue, label, data, placeholder, type, name, showPassword, ...props }) => {
    const [hidePassword, setHidePassword] = useState(true)
    return (
        <InputWrapper isBlue={isBlue}>
            {label.toString() && <CustomLabel htmlFor={name}>{label}</CustomLabel>}
            <input
                className={`form-control ${data.error ? 'is-invalid' : ''}`}
                type={hidePassword ? type : 'text'}
                value={data.value}
                placeholder={placeholder}
                name={name}
                {...props}
            />
            {type === 'password' && showPassword && (
                <EyeContentWrapper className='row'>
                    <EyePasswordWrapper onClick={() => setHidePassword(!hidePassword)}>
                        <img src={hidePassword ? eyeCloseSvg : eyeOpenSvg} width={20} />
                    </EyePasswordWrapper>
                </EyeContentWrapper>
            )}
            {data.error && <CustomError>{data.error}</CustomError>}
        </InputWrapper>
    )
}

CustomInput.propTypes = {
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number', 'password', 'email']),
    placeholder: PropTypes.string,
    data: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            value: PropTypes.string,
            error: PropTypes.string
        })
    ])
}

CustomInput.defaultProps = {
    label: '',
    placeholder: '',
    type: 'text',
    data: {
        value: '',
        error: ''
    }
}

export default CustomInput
