import React from 'react'
import { Link } from 'react-router-dom'
import { Body } from '../../../assets'

const HelpPage = ({ showTitle }) => {
    return (
        <div className="container">
            <div>
                {showTitle ? (
                    <p style={{ fontSize: 20, fontWeight: 600, color: '#6D6E71' }}>
                        Páginas de ayuda
                    </p>
                ) : (
                    <></>
                )}
                {showTitle ? <div className="copyright-text"></div> : <></>}
                <p style={{ fontSize: 16, color: '#797979' }}>
                    <Link to="/" type="secondary" style={{ color: Body.label.color }}>
                        Inicio
                    </Link>
                </p>
                <p style={{ fontSize: 16, color: '#797979' }}>
                    <Link to="/faqs" type="secondary" style={{ color: Body.label.color }}>
                        FAQs
                    </Link>
                </p>
            </div>
        </div>
    )
}

export default HelpPage
