import React, { useEffect, useState } from 'react'
import { Button } from '../../../assets'
import {
    Amount,
    BoxSection,
    CheckContainer,
    TitleBendo,
    DetailBendo,
    ItemRowBendo,
    LabelBendo,
    ValueBendo,
    Label,
    LabelTotal,
    PaySure,
    Politics,
    PoliticsLabel,
    PtoPLogo,
    StyledButton,
    Value,
    ValueTotal,
    StyledTYC,
    Section,
    StyledCheckbox
} from '../style'

const DesktopView = ({
    invoice,
    hasError,
    acceptTYC,
    acceptPP,
    requestURL,
    setAcceptTYC,
    setAcceptPP,
    reloadPage,
    isMobile
}) => {
    const policyURL = 'https://storage-maspagos-prod.s3.amazonaws.com/Politicas_de_Privacidad.pdf'
    const hideLenght = 80
    const [showTyc, setShowTyc] = useState(false)
    const tyc_string = invoice?.establishment?.tyc ?? ''
    const tyc_hide = tyc_string.substring(0, hideLenght)
    let endSection = ''
    if (invoice?.iva) {
        endSection = 'iva'
    }
    if (invoice?.discount && invoice?.discount > 0) {
        endSection = 'discount'
    }
    if (invoice?.services && invoice?.services > 0) {
        endSection = 'services'
    }
    if (invoice?.taxes && invoice?.taxes > 0) {
        endSection = 'taxes'
    }

    return (
        <div>
            <TitleBendo>{invoice?.establishment?.name}</TitleBendo>
            <DetailBendo className="row">
                <LabelBendo className="col-12 col-md-12 col-xl-6 col-lg-6">
                    Detalle del pago:{' '}
                </LabelBendo>
                <ValueBendo className="col-12 col-md-12 col-xl-6 col-lg-6">
                    {invoice?.details}
                </ValueBendo>
            </DetailBendo>
            <Section>
                <BoxSection style={{ borderRadius: '17.1px 17.1px 0px 0px' }}>
                    <ItemRowBendo className="row">
                        <LabelTotal
                            className="col-6 col-md-6"
                            style={{ paddingLeft: !isMobile ? '110px' : '35px' }}
                        >
                            Total a pagar:
                        </LabelTotal>
                        <ValueTotal className="col-6 col-md-6 text-left">
                            ${Number(invoice?.total ?? 0.0).toFixed(2)}
                        </ValueTotal>
                    </ItemRowBendo>
                </BoxSection>
                <BoxSection>
                    <ItemRowBendo className="row">
                        <Label
                            className="col-6 col-md-6"
                            style={{ paddingLeft: !isMobile ? '110px' : '35px' }}
                        >
                            Subtotal:
                        </Label>
                        <Value className="col-6 col-md-6 text-left">
                            ${Number(invoice?.subtotal).toFixed(2)}
                        </Value>
                    </ItemRowBendo>
                </BoxSection>
                <BoxSection
                    style={{
                        borderRadius:
                            endSection == 'iva' ? '0px 0px 17.1px 17.1px' : '0px 0px 0px 0px'
                    }}
                >
                    <ItemRowBendo className="row">
                        <Label
                            className="col-6 col-md-6"
                            style={{ paddingLeft: !isMobile ? '110px' : '35px' }}
                        >
                            IVA:
                        </Label>
                        <Value className="col-6 col-md-6 text-left">
                            ${Number(invoice?.iva ?? 0.0).toFixed(2)}
                        </Value>
                    </ItemRowBendo>
                </BoxSection>

                {invoice?.discount && invoice?.discount > 0 ? (
                    <BoxSection
                        style={{
                            borderRadius:
                                endSection == 'discount'
                                    ? '0px 0px 17.1px 17.1px'
                                    : '0px 0px 0px 0px'
                        }}
                    >
                        <ItemRowBendo className="row">
                            <Label
                                className="col-6 col-md-6"
                                style={{ paddingLeft: !isMobile ? '110px' : '35px' }}
                            >
                                Descuento:
                            </Label>
                            <Value className="col-6 col-md-6 text-left">
                                ${Number(invoice?.discount ?? 0.0).toFixed(2)}
                            </Value>
                        </ItemRowBendo>
                    </BoxSection>
                ) : (
                    <></>
                )}

                {invoice?.services && invoice?.services > 0 ? (
                    <BoxSection
                        style={{
                            borderRadius:
                                endSection == 'services'
                                    ? '0px 0px 17.1px 17.1px'
                                    : '0px 0px 0px 0px'
                        }}
                    >
                        <ItemRowBendo className="row">
                            <Label
                                className="col-6 col-md-6"
                                style={{ paddingLeft: !isMobile ? '110px' : '35px' }}
                            >
                                Servicio:
                            </Label>
                            <Value className="col-6 col-md-6 text-left">
                                ${Number(invoice?.services ?? 0.0).toFixed(2)}
                            </Value>
                        </ItemRowBendo>
                    </BoxSection>
                ) : (
                    <></>
                )}

                {invoice?.taxes && invoice?.taxes > 0 ? (
                    <BoxSection
                        style={{
                            borderRadius:
                                endSection == 'taxes' ? '0px 0px 17.1px 17.1px' : '0px 0px 0px 0px'
                        }}
                    >
                        <ItemRowBendo className="row">
                            <Label
                                className="col-6 col-md-6"
                                style={{ paddingLeft: !isMobile ? '110px' : '35px' }}
                            >
                                Otros impuestos:
                            </Label>
                            <Value className="col-6 col-md-6 text-left">
                                ${Number(invoice?.taxes ?? 0.0).toFixed(2)}
                            </Value>
                        </ItemRowBendo>
                    </BoxSection>
                ) : (
                    <></>
                )}
            </Section>
            <Politics className="row">
                <PoliticsLabel className="row">
                    <span>
                        <span style={{ fontWeight: 700 }}>Políticas de pago: </span>
                        {invoice?.establishment?.tyc_url ? (
                            <>
                                <span>encuéntralas{''}</span>
                                <StyledTYC
                                    href={invoice?.establishment?.tyc_url ?? '#'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    aquí
                                </StyledTYC>
                            </>
                        ) : invoice?.establishment?.tyc ? (
                            <>
                                <span>{showTyc ? tyc_string : tyc_hide}</span>
                                <a
                                    onClick={() => setShowTyc(showTyc ? false : true)}
                                    style={{
                                        color: Button.primary.defaultColor.active.color,
                                        cursor: 'pointer'
                                    }}
                                >
                                    {' '}
                                    {tyc_string.length <= hideLenght
                                        ? ''
                                        : showTyc
                                        ? 'Mostrar menos'
                                        : 'Mostrar más'}
                                </a>
                            </>
                        ) : (
                            'Sin políticas'
                        )}
                    </span>
                </PoliticsLabel>
            </Politics>

            {!hasError && (
                <CheckContainer className="col-12 row">
                    <StyledCheckbox>
                        <input
                            type="checkbox"
                            id="acceptTYC"
                            checked={acceptTYC}
                            onChange={(e) => {
                                setAcceptTYC(e.target.checked)
                            }}
                        />
                        <label htmlFor="acceptTYC">
                            <span>
                                He leído y acepto los{' '}
                                {invoice?.establishment?.tyc_url ? (
                                    <StyledTYC
                                        href={invoice?.establishment?.tyc_url ?? '#'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {' '}
                                        Términos y condiciones.
                                    </StyledTYC>
                                ) : (
                                    'Términos y condiciones.'
                                )}
                            </span>
                        </label>
                    </StyledCheckbox>
                    <StyledCheckbox>
                        <input
                            type="checkbox"
                            id="acceptPP"
                            checked={acceptPP}
                            onChange={(e) => {
                                setAcceptPP(e.target.checked)
                            }}
                        />
                        <label htmlFor="acceptPP">
                            <span>
                                He leído y acepto el tratamiento de mis datos personales de acuerdo
                                con las{' '}
                                <StyledTYC
                                    href={policyURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {' '}
                                    Políticas de Privacidad.
                                </StyledTYC>
                            </span>
                        </label>
                    </StyledCheckbox>
                </CheckContainer>
            )}

            <div className="row" style={{ paddingBottom: 10 }}>
                <div className="col-12">
                    {!hasError ? (
                        <div>
                            <StyledButton
                                disabled={!acceptTYC || !acceptPP}
                                onClick={requestURL}
                                disableBackground="#D3D3D3"
                                disableColor="#ffffff"
                            >
                                Realizar pago
                            </StyledButton>
                            <div className="text-center">
                                <PaySure>Tu pago seguro con: </PaySure>
                                <PtoPLogo
                                    src="https://static.placetopay.com/placetopay-logo.svg"
                                    alt="Placetopay"
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Amount>Hay un intento de pago en proceso, por seguridad ingresa en unos minutos.</Amount>
                            <StyledButton onClick={reloadPage}>Refrescar Página</StyledButton>
                            <div className="text-center">
                                <PaySure>Tu pago seguro con: </PaySure>
                                <PtoPLogo
                                    src="https://static.placetopay.com/placetopay-logo.svg"
                                    alt="Placetopay"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DesktopView
