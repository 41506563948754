import React, { useContext, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { InnerForm } from '../../../../components/Form/style'
import IdentifactionTypeService from '../../../../services/Util/IdenticationType'
import AuthContext from '../../../../context/AuthContext'
import ContentSecondInput from './ContentSecondInput'
import { fillEstablishments, handleCurrent } from './helpers'

const schema = yup.object().shape({
    identificationTypeID: yup.mixed().required('Seleccionar tipo de identificación'),
    identification: yup.string().required('Número de identificación requerido'),
    establishmentType: yup.mixed().required('Seleccionar tipo de local'),
    establishmentID: yup.mixed().when('establishmentType', {
        is: (establishmentType) => establishmentType?.value === 1,
        then: yup.mixed().required('Seleccionar local')
    })
})

const SecondStep = ({
    setActionForm,
    setRequestData,
    requestData,
    current,
    sendData,
    errorRequest
}) => {
    const { token } = useContext(AuthContext)

    const optionsEstablishmentType = [
        { label: 'Simple', value: 1 },
        { label: 'Múltiple', value: 2 }
    ]

    const [sendFormUpdate, setSendFormUpdate] = useState(false)
    const [optionsIdentificationType, setOptionsIdentificationType] = useState([])
    const [optionsEstablishments, setOptionsEstablishments] = useState([])
    const [selectedEstablishmentType, setSelectedEstablismentType] = useState()
    const {
        handleSubmit,
        errors,
        getValues,
        setError,
        clearErrors,
        watch,
        setValue,
        control
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
        criteriaMode: 'firstError',
        shouldFocusError: true,
        defaultValues: {
            ...requestData,
            establishmentType: requestData?.establishmentID
                ? { label: 'Simple', value: 1 }
                : { label: 'Multiple', value: 2 },
            identificationTypeID: requestData.identificationTypeID
                ? requestData.identificationTypeID
                : {}
        }
    })

    const onSubmit = (data) => {
        setRequestData((prevData) => {
            const { ...sanitizatedData } = prevData
            return { ...sanitizatedData, ...data }
        })
        setSendFormUpdate(true)
    }

    const fillIdentificationType = async () => {
        const result = await IdentifactionTypeService.get(token)
        if (result) {
            setOptionsIdentificationType(
                result.data.data.map((identificationType) => ({
                    value: identificationType.id,
                    label: identificationType.name
                }))
            )
        }
    }

    useEffect(() => {
        fillIdentificationType()
    }, [])

    useEffect(() => {
        if (sendFormUpdate) {
            sendData()
        }
        setSendFormUpdate(false)
    }, [sendFormUpdate])

    useEffect(() => {
        if (optionsIdentificationType.length > 0) {
            const found = optionsIdentificationType.find(
                (option) => option.value === requestData.identificationTypeID.value
            )

            setValue('identificationTypeID', found)
        }
    }, [optionsIdentificationType])

    useEffect(() => {
        const selected = getValues('establishmentType')?.value
        if (selected) {
            setSelectedEstablismentType(selected)
            setValue('establishmentID', '')
            if (selected === 1) {
                fillEstablishments(optionsEstablishments, token, setOptionsEstablishments)
            }
        }
    }, [watch('establishmentType')])

    useEffect(() => {
        handleCurrent(current, setActionForm, handleSubmit, onSubmit, errorRequest, setError)
    }, [current, errorRequest])

    return (
        <InnerForm autoComplete="off" current={current}>
            <ContentSecondInput
                optionsIdentificationType={optionsIdentificationType}
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                setValue={setValue}
                getValues={getValues}
                optionsEstablishmentType={optionsEstablishmentType}
                selectedEstablishmentType={selectedEstablishmentType}
                optionsEstablishments={optionsEstablishments}
                token={token}
                setError={setError}
            />
        </InnerForm>
    )
}

export default SecondStep
